import { ApiError, PawPrintApi } from '@prenuvo/paw-print-shared';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { ENDPOINTS } from '@/api/endpoints';
import { fetcher } from '@/api/fetcher';
import { FeatureFlagsResponse } from '@/api/model';
import { CONFIG } from '@/core';

const KEY = {
  FEATURE_FLAGS: 'FEATURE_FLAGS',
};

const request = {
  getFeatureFlags: async (api: PawPrintApi, signal?: AbortSignal) => {
    const { data } = await fetcher.api.get(`${CONFIG.BASE_URL[api]}${ENDPOINTS.FEATURE_FLAGS}`, {
      signal,
    });

    return data;
  },
};

const query = {
  useGetFeatureFlags: (
    api: PawPrintApi,
    config?: UseQueryOptions<FeatureFlagsResponse, ApiError>,
  ) =>
    useQuery<FeatureFlagsResponse, ApiError>({
      queryFn: ({ signal }) => request.getFeatureFlags(api, signal),
      queryKey: [KEY.FEATURE_FLAGS],
      ...config,
    }),
};

export const featureFlags = {
  KEY,
  query,
  request,
};

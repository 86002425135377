import { cva } from 'class-variance-authority';

export const iconVariants = cva('', {
  variants: {
    size: {
      lg: 'size-8', // 32px
      md: 'size-6', // 24px
      sm: 'size-5', // 20px
      xs: 'size-4', // 16px
    },
  },
});

import { cn } from '@/util';

import { Icon } from '../icon';
import { ICON_SIZE_MAP } from './button.constant';
import { ButtonCommonProps } from './button.types';

export function ButtonLayout({
  children,
  className,
  leftIcon,
  rightIcon,
  size = 'md',
}: ButtonCommonProps) {
  return (
    <>
      {leftIcon?.icon && (
        <Icon
          aria-hidden
          className={cn('flex shrink-0 fill-current', className?.leftIcon)}
          icon={leftIcon.icon}
          size={ICON_SIZE_MAP[size]}
        />
      )}

      <span
        className={cn('mb-0.5 truncate py-1.5 text-inherit dark:text-inherit', className?.text)}
      >
        {children}
      </span>

      {rightIcon?.icon && (
        <Icon
          aria-hidden
          className={cn('flex shrink-0 fill-current', className?.rightIcon)}
          icon={rightIcon.icon}
          size={ICON_SIZE_MAP[size]}
        />
      )}
    </>
  );
}

import { Button } from '../button';
import { Cross, Icon } from '../icon';
import { ModalProps } from './modal.model';

export interface ModalHeaderProps extends Pick<ModalProps, 'isModalLocked' | 'onClose' | 'title'> {}

export function ModalHeader({ isModalLocked, onClose, title }: ModalHeaderProps) {
  return (
    <div className="flex items-center justify-between rounded-t border-b border-solid p-5">
      <h3 id="modal-title" className="text-2xl font-semibold">
        {title}
      </h3>

      <Button
        aria-label="Close Modal"
        className={{
          root: 'float-right ml-auto border-0 bg-transparent p-1 text-3xl font-semibold leading-none text-black outline-none focus:outline-none',
        }}
        disabled={isModalLocked}
        onClick={onClose}
        size="xs"
        variant="text"
      >
        <Icon aria-hidden className="fill-current" icon={<Cross />} size="xs" />
      </Button>
    </div>
  );
}

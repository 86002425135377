import { AriaAttributes } from 'react';

import { QueryableComponent } from '@/model';
import { cn } from '@/util';

export interface LoaderProps extends Pick<AriaAttributes, 'aria-label'>, QueryableComponent {
  className?: string;
}

export function Loader({
  'aria-label': ariaLabel = 'Loader',
  className,
  'data-testid': dataTestId = 'paw-print-loader',
}: LoaderProps) {
  return (
    <div className={cn('size-10 animate-spin', className)}>
      <svg
        aria-label={ariaLabel}
        className="text-blue-600"
        data-testid={dataTestId}
        fill="none"
        role="img"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle className="opacity-25" cx="12" cy="12" r="10" />
        <path className="opacity-75" d="M12 2a10 10 0 0 1 10 10" />
      </svg>
    </div>
  );
}

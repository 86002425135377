import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * a convenience function to merge tailwindcss classes with clsx and tailwind-merge
 * what is cn? see this youtube video https://www.youtube.com/watch?v=re2JFITR7TI
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

import { SvgProps } from '../icon.types';

export function InformationCircle(props: SvgProps) {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M8 5.769c.266 0 .481.215.481.481v5.688c0 .265-.215.48-.481.48s-.481-.215-.481-.48v-5.688c0-.266.215-.481.481-.481"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M8 3.581c.266 0 .481.216.481.482v.437c0 .266-.215.481-.481.481s-.481-.215-.481-.481v-.437c0-.266.215-.482.481-.482"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M1.963 8c0 3.334 2.703 6.038 6.037 6.038s6.038-2.704 6.038-6.038-2.704-6.037-6.038-6.037-6.037 2.703-6.037 6.037m6.037-7c-3.866 0-7 3.134-7 7s3.134 7 7 7 7-3.134 7-7-3.134-7-7-7"
        fillRule="evenodd"
      />
    </svg>
  );
}

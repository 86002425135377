import { ReactNode } from 'react';

import { cn } from '@/util';

interface ModalOverlayProps {
  children: ReactNode;
  isOpen: boolean;
}

export function ModalOverlay({ children, isOpen }: ModalOverlayProps) {
  return (
    <div
      aria-labelledby="modal-title"
      aria-modal
      className={cn(
        'fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-black outline-none focus:outline-none transition-opacity duration-300 ease-in-out',
        { 'bg-opacity-0': !isOpen, 'bg-opacity-50': isOpen },
      )}
      role="dialog"
    >
      {children}
    </div>
  );
}

import { ButtonProps } from './button.types';

export const ICON_SIZE_MAP: Record<
  NonNullable<ButtonProps['size']>,
  NonNullable<ButtonProps['size']>
> = {
  lg: 'md',
  md: 'md',
  sm: 'sm',
  xs: 'sm',
};

import { ReactElement } from 'react';
import { PawPrintApi } from '@prenuvo/paw-print-shared';
import { QueryClientProvider } from '@tanstack/react-query';

import { FeatureFlag } from '@/api/model';
import { queryClient } from '@/core';

import { FeatureGuardState } from './feature-guard-state';

interface FeatureGuardProps {
  api?: PawPrintApi;
  children: ReactElement;
  featureFlag: FeatureFlag;
}

/**
 * @param api - Default api value to 'PROD_API', so it does not need to be changed in the consuming libraries.
 * 'PROD_API' - will set the base url of api requests to the production environment api.
 * 'STAGING_API' - will set the base url of api requests to the staging environment api.
 * 'LOCAL_API' - will set the base url of api requests to a locally running api.
 * 'DEV_API' - will set the base url of api requests to be the mocked api.
 */
export function FeatureGuard({ api = 'PROD_API', children, featureFlag }: FeatureGuardProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <FeatureGuardState api={api} featureFlag={featureFlag}>
        {children}
      </FeatureGuardState>
    </QueryClientProvider>
  );
}

import { cloneElement, forwardRef, ReactElement, SVGProps } from 'react';

import { CvaVariantProps, QueryableComponent } from '@/model';
import { cn } from '@/util';

import { iconVariants } from './icon.cva';

export interface IconProps
  extends SVGProps<SVGSVGElement>,
    QueryableComponent,
    CvaVariantProps<typeof iconVariants> {
  className?: string;
  icon: ReactElement;
}

export const Icon = forwardRef<SVGSVGElement, IconProps>(
  (
    { className, 'data-testid': dataTestId = 'paw-print-icon', icon, size = 'md', ...props },
    ref,
  ) => (
    <span className={cn('block', iconVariants({ size }), className)} data-testid={dataTestId}>
      {cloneElement(icon, {
        className: 'relative block size-full fill-current',
        focusable: false,
        ref,
        role: 'img',
        ...props,
      })}
    </span>
  ),
);

Icon.displayName = 'Icon';

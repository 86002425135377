import ReactDOM from 'react-dom';

import { Toast } from './toast';
import { useToasts } from './use-toasts.hook';

export function ToastContainer() {
  const { onRemoveToast, toasts } = useToasts();

  return ReactDOM.createPortal(
    <div
      aria-atomic
      aria-live="polite"
      className="fixed bottom-0 right-0 top-auto z-[100] flex max-h-screen w-full flex-col p-4 md:max-w-[420px]"
    >
      {toasts.map((toast) => (
        <Toast key={toast.id} onRemoveToast={onRemoveToast} {...toast} />
      ))}
    </div>,
    document.body,
  );
}

import { useEffect } from 'react';

import { cn } from '@/util';

import { Cross, Icon } from '../icon';
import { toastVariants } from './toast.cva';
import { Toast as ToastModel } from './toast.model';

export interface ToastProps extends ToastModel {
  onRemoveToast: (id: string) => void;
}

export function Toast({
  id,
  description,
  duration = 5000,
  onRemoveToast,
  title,
  variant,
}: ToastProps) {
  useEffect(() => {
    const timeout = setTimeout(() => onRemoveToast(id), duration);

    return () => clearTimeout(timeout);
  }, [duration, id, onRemoveToast]);

  return (
    <div
      className={cn([
        'relative group pointer-events-auto overflow-hidden mb-5 flex space-x-4 w-full rounded-xl px-4 py-3.5 shadow-lg transition-all shrink-0',
        toastVariants({ variant }),
      ])}
      data-testid="paw-print-toast"
      role="alert"
    >
      <div className="flex flex-col pr-3">
        <h4 className="text-lg font-semibold text-inherit">{title}</h4>
        <p className="text-sm text-inherit">{description}</p>
      </div>

      <button
        aria-label="Close Toast"
        className={cn(
          'absolute opacity-0 transition-opacity duration-300 text-neutral-50 outline-none right-3.5 top-3.5 rounded-full p-1.5',
          'group-hover:opacity-100',
          'hover:bg-neutral-900/50 focus:bg-neutral-900',
          'group-[.error]:text-red-800 group-[.error]:hover:bg-red-200/50 group-[.error]:focus:bg-red-200',
          'group-[.success]:text-green-800 group-[.success]:hover:bg-green-50/50 group-[.success]:focus:bg-green-50',
          'group-[.warning]:text-orange-800 group-[.warning]:hover:bg-orange-50/50 group-[.warning]:focus:bg-orange-50',
        )}
        onClick={() => onRemoveToast(id)}
        type="button"
      >
        <Icon aria-hidden className="fill-current" icon={<Cross />} size="xs" />
      </button>
    </div>
  );
}

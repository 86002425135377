import { SvgProps } from '../icon.types';

export function ChevronLeft(props: SvgProps) {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M10.344 3.142c.19.19.19.498 0 .688l-4.17 4.17 4.17 4.17c.19.19.19.498 0 .688s-.498.19-.688 0l-4.514-4.514c-.19-.19-.19-.498 0-.688l4.514-4.514c.19-.19.498-.19.688 0"
        fillRule="evenodd"
      />
    </svg>
  );
}

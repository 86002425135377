import { cva } from 'class-variance-authority';

export const iconContainerVariants = cva('', {
  variants: {
    variant: {
      default: 'bg-white text-black',
      error: 'bg-red-100 text-red-600 ',
      success: 'bg-green-50 text-green-600 ',
    },
  },
});

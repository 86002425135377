import { ReactElement, SVGProps } from 'react';

import { CvaVariantProps, QueryableComponent } from '@/model';
import { cn } from '@/util';

import { Icon } from '../icon';
import { iconContainerVariants } from './icon-container.cva';

export interface IconContainerProps
  extends QueryableComponent,
    Omit<SVGProps<SVGSVGElement>, 'color' | 'ref'>,
    CvaVariantProps<typeof iconContainerVariants> {
  className?: string;
  icon: ReactElement;
}

export function IconContainer({
  className,
  'data-testid': dataTestId = 'paw-print-error-icon-container',
  icon,
  variant = 'default',
  ...props
}: IconContainerProps) {
  return (
    <div
      className={cn('p-3 rounded-full', iconContainerVariants({ variant }), className)}
      data-testid={dataTestId}
    >
      <Icon aria-hidden className="fill-current" icon={icon} {...props} />
    </div>
  );
}

import { SvgProps } from '../icon.types';

export function CalendarBooked(props: SvgProps) {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M13.25 5.069h-10.5c-.435 0-.788.352-.788.787v6.563c0 .435.353.787.788.787h10.5c.435 0 .787-.352.787-.787v-6.563c0-.435-.352-.787-.787-.787m-10.5-.963c-.966 0-1.75.784-1.75 1.75v6.563c0 .966.784 1.75 1.75 1.75h10.5c.966 0 1.75-.784 1.75-1.75v-6.563c0-.966-.784-1.75-1.75-1.75z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M4.063 1c.265 0 .48.215.48.481v1.313c0 .266-.215.481-.48.481-.266 0-.482-.215-.482-.481v-1.313c0-.266.216-.481.482-.481"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M11.938 1c.265 0 .48.215.48.481v1.313c0 .266-.215.481-.48.481-.266 0-.482-.215-.482-.481v-1.313c0-.266.216-.481.482-.481"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M5.035 8.666c.188-.188.492-.188.68 0l1.925 1.925c.188.188.188.493 0 .68s-.492.188-.68 0l-1.925-1.924c-.188-.188-.188-.493 0-.681"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M11.422 7.286c.177.198.16.502-.04.68l-3.727 3.324c-.198.177-.502.16-.68-.038-.176-.199-.159-.503.04-.68l3.727-3.325c.198-.177.503-.16.68.039"
        fillRule="evenodd"
      />
    </svg>
  );
}

import { SvgProps } from '../icon.types';

export function Duplicate(props: SvgProps) {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M13.557 2.058h-7.505c-.213 0-.385.173-.385.385v7.505c0 .213.172.385.385.385h7.505c.212 0 .384-.172.384-.385v-7.505c0-.212-.172-.385-.384-.385m-7.505-1.058c-.798 0-1.444.646-1.444 1.443v7.505c0 .798.646 1.444 1.444 1.444h7.505c.797 0 1.443-.646 1.443-1.444v-7.505c0-.797-.646-1.443-1.443-1.443z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M1 5.522c0-1.089.883-1.972 1.973-1.972h.288c.292 0 .53.237.53.53 0 .291-.238.528-.53.528h-.288c-.505 0-.915.41-.915.914v7.506c0 .504.41.914.915.914h7.505c.505 0 .914-.41.914-.915v-.288c0-.292.237-.53.529-.53s.53.238.53.53v.288c0 1.09-.884 1.973-1.973 1.973h-7.505c-1.09 0-1.973-.883-1.973-1.973z"
        fillRule="evenodd"
      />
    </svg>
  );
}

import { SvgProps } from '../icon.types';

export function TimeLg(props: SvgProps) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M12 20.75c4.833 0 8.75-3.918 8.75-8.75s-3.918-8.75-8.75-8.75-8.75 3.918-8.75 8.75c0 4.833 3.918 8.75 8.75 8.75m0 1.25c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12 6.375c.345 0 .625.28.625.625v5.104c0 .328.154.637.417.834l3.083 2.312c.276.207.332.599.125.875s-.599.332-.875.125l-3.083-2.312c-.577-.433-.917-1.113-.917-1.834v-5.104c0-.345.28-.625.625-.625"
        fillRule="evenodd"
      />
    </svg>
  );
}

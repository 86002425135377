'use client';

import { forwardRef } from 'react';

import { cn } from '@/util';

import { buttonVariants } from './button.cva';
import { ButtonBaseProps } from './button.types';

export const ButtonBase = forwardRef<HTMLButtonElement, ButtonBaseProps>(
  (
    {
      asChild,
      children,
      className,
      'data-testid': dataTestId = 'paw-print-button',
      size,
      type = 'button',
      variant = 'primary',
      ...props
    },
    ref,
  ) => (
    <button
      className={cn(buttonVariants({ size, variant }), className?.root)}
      data-testid={dataTestId}
      ref={ref}
      // eslint-disable-next-line react/button-has-type
      type={type}
      {...props}
    >
      {children}
    </button>
  ),
);

ButtonBase.displayName = 'ButtonBase';

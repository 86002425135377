import { ReactNode, useCallback, useMemo, useState } from 'react';

import { ToastContext } from './toast.context';
import { Toast } from './toast.model';
import { genId } from './toast.util';
import { ToastContainer } from './toast-container';

interface ToastProviderProps {
  children: ReactNode;
  limit?: number;
}

export function ToastProvider({ children, limit = 1 }: ToastProviderProps) {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const handleAddToast = useCallback(
    (payload: Omit<Toast, 'id'>) => {
      const id = genId();
      const toast = { id, ...payload };
      const TOASTS = [toast, ...toasts];

      setToasts(limit ? TOASTS.slice(0, limit) : TOASTS);

      return id;
    },
    [limit, toasts],
  );

  const handleRemoveToast = useCallback((id: string) => {
    setToasts((prev) => prev.filter((toast) => toast.id !== id));
  }, []);

  const value = useMemo(
    () => ({
      onAddToast: handleAddToast,
      onRemoveToast: handleRemoveToast,
      toasts,
    }),
    [handleAddToast, handleRemoveToast, toasts],
  );

  return (
    <ToastContext.Provider value={value}>
      {children}
      <ToastContainer />
    </ToastContext.Provider>
  );
}

import { HttpError } from '@prenuvo/paw-print-shared';
import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        if (error instanceof HttpError && (error.status === 401 || error.status === 404)) {
          return false;
        }

        if (error instanceof HttpError && error.status === 504 && failureCount < 1) {
          return false;
        }

        return failureCount < 2;
      },
    },
  },
});

import { SvgProps } from '../icon.types';

export function CrossCircle(props: SvgProps) {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M1.963 8c0 3.334 2.703 6.038 6.037 6.038s6.038-2.704 6.038-6.038-2.704-6.037-6.038-6.037-6.037 2.703-6.037 6.037m6.037-7c-3.866 0-7 3.134-7 7s3.134 7 7 7 7-3.134 7-7-3.134-7-7-7"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M4.743 4.743c.188-.188.493-.188.68 0l5.852 5.852c.188.188.188.492 0 .68s-.492.188-.68 0l-5.852-5.851c-.188-.188-.188-.493 0-.681"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M11.464 4.743c-.188-.188-.493-.188-.68 0l-5.852 5.852c-.188.188-.188.492 0 .68s.492.188.68 0l5.852-5.851c.188-.188.188-.493 0-.681"
        fillRule="evenodd"
      />
    </svg>
  );
}

import { ReactElement } from 'react';
import { PawPrintApi } from '@prenuvo/paw-print-shared';

import { FeatureFlag } from '@/api/model';
import { api as API } from '@/api/query';

interface FeatureGuardStateProps {
  api: PawPrintApi;
  children: ReactElement;
  featureFlag: FeatureFlag;
}

/**
 * @param api - Default api value to 'PROD_API', so it does not need to be changed in the consuming libraries.
 * 'PROD_API' - will set the base url of api requests to the production environment api.
 * 'STAGING_API' - will set the base url of api requests to the staging environment api.
 * 'LOCAL_API' - will set the base url of api requests to a locally running api.
 * 'DEV_API' - will set the base url of api requests to be the mocked api.
 */
export function FeatureGuardState({ api, children, featureFlag }: FeatureGuardStateProps) {
  const { data, isError, isLoading } = API.featureFlags.query.useGetFeatureFlags(api);

  if (isLoading) {
    return (
      <div
        aria-hidden
        style={{
          borderWidth: '0',
          clip: 'rect(0, 0, 0, 0)',
          height: '1px',
          margin: '-1px',
          overflow: 'hidden',
          padding: '0',
          position: 'absolute',
          whiteSpace: 'nowrap',
          width: '1px',
        }}
      >
        Feature Flags Loading
      </div>
    );
  }

  if (isError) {
    return null;
  }

  if (data[featureFlag]) {
    return children;
  }

  return null;
}

import { SvgProps } from '../icon.types';

export function CheckmarkCircle(props: SvgProps) {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M8 14.038c3.334 0 6.038-2.704 6.038-6.038s-2.704-6.037-6.038-6.037-6.037 2.703-6.037 6.037 2.703 6.038 6.037 6.038m0 .962c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M4.84 7.835c.188-.188.493-.188.68 0l1.926 1.925c.188.188.188.492 0 .68s-.493.188-.68 0l-1.926-1.925c-.188-.188-.188-.492 0-.68"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M11.826 5.565c.185.191.18.496-.01.68l-4.34 4.2c-.192.186-.496.18-.681-.01-.185-.191-.18-.496.01-.68l4.34-4.2c.192-.186.497-.18.681.01"
        fillRule="evenodd"
      />
    </svg>
  );
}

import { SvgProps } from '../icon.types';

export function Cross(props: SvgProps) {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M14.772 1.228c.304.304.304.796 0 1.1l-5.672 5.672 5.672 5.672c.304.304.304.797 0 1.1-.303.304-.796.304-1.1 0l-5.672-5.672-5.672 5.672c-.304.304-.796.304-1.1 0-.304-.303-.304-.796 0-1.1l5.672-5.672-5.672-5.672c-.304-.304-.304-.796 0-1.1s.796-.304 1.1 0l5.672 5.672 5.672-5.672c.304-.304.797-.304 1.1 0"
        fillRule="evenodd"
      />
    </svg>
  );
}

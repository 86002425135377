export class HttpError extends Error {
  status: number;

  url: string;

  constructor(public response: Response) {
    super(response.statusText);

    this.name = 'HTTPError';
    this.status = response.status;
    this.url = response.url;
  }
}

import { cva } from 'class-variance-authority';

export const buttonVariants = cva(
  'group/button relative flex items-center justify-between gap-2 rounded-full px-4 transition-all duration-300',
  {
    variants: {
      size: {
        lg: 'h-14',
        md: 'h-12',
        sm: 'h-10',
        xs: 'h-8',
      },
      variant: {
        primary: [
          [
            'border-2 border-green-700 bg-green-700 text-neutral-50',
            'hover:shadow-button-primary hover:border-green-600 hover:bg-green-600',
            'active:border-green-800 active:bg-green-800',
            'disabled:border-neutral-100/0 disabled:bg-neutral-100/50 disabled:text-neutral-200 disabled:shadow-none',
          ],
          [
            'dark:disabled:border-neutral-900 dark:disabled:bg-neutral-900 dark:disabled:text-neutral-800',
          ],
        ],
        text: [
          [
            'border-2 border-transparent bg-transparent',
            'hover:border-neutral-50 hover:bg-neutral-50',
            'active:border-neutral-300 active:bg-neutral-300',
            'disabled:border-transparent disabled:bg-transparent disabled:text-neutral-200',
          ],
          [
            'dark:text-neutral-50',
            'dark:hover:border-neutral-900 dark:hover:bg-neutral-900',
            'dark:active:border-blue-600/0 dark:active:bg-blue-600/50',
            'dark:disabled:border-transparent dark:disabled:bg-transparent dark:disabled:text-neutral-800',
          ],
        ],
      },
    },
  },
);

import { FeatureFlagsResponse } from '@/api/model';

import { FEATURE_FLAG_SCENARIOS, FeatureFlagId } from './feature-flag.scenario';

export const FEATURE_FLAG_FIXTURE: Record<FeatureFlagId, FeatureFlagsResponse> = {
  [FEATURE_FLAG_SCENARIOS.DISABLED]: {
    renderedBookingWidget: false,
    renderedWorkflowInitButton: false,
  },
  [FEATURE_FLAG_SCENARIOS.ENABLED]: {
    renderedBookingWidget: true,
    renderedWorkflowInitButton: true,
  },
};

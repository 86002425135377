import { SvgProps } from '../icon.types';

export function WarningTriangle(props: SvgProps) {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M6.343 2.92c.764-1.227 2.55-1.227 3.314 0l5.045 8.103c.81 1.3-.125 2.983-1.657 2.983h-10.09c-1.532 0-2.466-1.683-1.657-2.983zm2.425.554c-.354-.569-1.182-.569-1.536 0l-5.045 8.102c-.375.603.058 1.383.768 1.383h10.09c.71 0 1.143-.78.768-1.383z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M8 9.39c-.29 0-.524-.234-.524-.524v-2.856c0-.289.235-.523.524-.523s.524.234.524.523v2.857c0 .289-.235.523-.524.523"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M8 11.294c.29 0 .524-.234.524-.523v-.476c0-.29-.235-.524-.524-.524s-.524.234-.524.524v.476c0 .289.235.523.524.523"
        fillRule="evenodd"
      />
    </svg>
  );
}

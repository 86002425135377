import { useEffect, useRef } from 'react';

import { useClickOutside } from '@/hook';
import { cn } from '@/util';

import { ModalProps } from './modal.model';
import { ModalHeader } from './modal-header';
import { ModalOverlay } from './modal-overlay';

export interface ModalBaseProps extends ModalProps {}

export function ModalBase({ children, isModalLocked, isOpen, onClose, title }: ModalBaseProps) {
  const modalRef = useRef<HTMLDivElement>(null);

  useClickOutside(modalRef, onClose, isModalLocked);

  useEffect(() => {
    if (isOpen && modalRef.current) {
      modalRef.current.focus();
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <ModalOverlay isOpen={isOpen}>
      <div
        className={cn(
          'relative mx-auto my-6 w-auto max-w-3xl min-w-[384px] md:min-w-[576px] transition-all duration-300 ease-in-out transform',
          { 'scale-100 opacity-100': isOpen, 'scale-95 opacity-0': !isOpen },
        )}
        ref={modalRef}
      >
        <div className="mx-6 rounded-lg bg-white shadow-lg">
          <ModalHeader isModalLocked={isModalLocked} onClose={onClose} title={title} />

          <div className="relative flex-auto p-6">{children}</div>
        </div>
      </div>
    </ModalOverlay>
  );
}

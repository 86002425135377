import { delay, http, HttpResponse } from 'msw';

import { ENDPOINTS } from '@/api/endpoints';

import { FEATURE_FLAG_FIXTURE } from './feature-flag.fixture';
import { FEATURE_FLAG_SCENARIOS } from './feature-flag.scenario';

export const getFeatureFlags = http.get(`/mock-api${ENDPOINTS.FEATURE_FLAGS}`, async () => {
  await delay(300);

  return HttpResponse.json(FEATURE_FLAG_FIXTURE[FEATURE_FLAG_SCENARIOS.ENABLED]);
});

export const featureFlagHandlers = [getFeatureFlags];

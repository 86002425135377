import { forwardRef } from 'react';

import { ButtonProps } from './button.types';
import { ButtonBase } from './button-base';
import { ButtonLayout } from './button-layout';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { children, className, leftIcon, rightIcon, size = 'md', variant = 'primary', ...props },
    ref,
  ) => (
    <ButtonBase
      className={{ root: className?.root }}
      ref={ref}
      size={size}
      variant={variant}
      {...props}
    >
      <ButtonLayout
        className={className}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        size={size}
        variant={variant}
      >
        {children}
      </ButtonLayout>
    </ButtonBase>
  ),
);

Button.displayName = 'Button';

import { cva } from 'class-variance-authority';

export const toastVariants = cva([], {
  defaultVariants: {
    variant: 'default',
  },
  variants: {
    variant: {
      default: 'bg-neutral-950 text-neutral-50',
      error: 'error bg-red-100 text-red-800',
      success: 'success bg-green-100 text-green-800',
      warning: 'warning bg-orange-100 text-orange-800',
    },
  },
});
